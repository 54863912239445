const NoPage = () => {
    return (
      <>
      <div className="center main-content">
          <h1 style={{textAlign:'center'}}>404: Page not found</h1>
      </div>
      <h2>Umm, this is awkward.</h2>
      <p>The page you are looking for cannot be found.</p>
      </>
    );
    };
    
    export default NoPage;  